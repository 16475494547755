import React from "react"
import styled from "styled-components"
import logo from "../../images/Logo.png"
import { LARGE, MEDIUM, TAB } from "../../constants/breakpoints"
import * as St from "../Styled"
import {
  PROP_FONT_PRIMARY
} from "../../constants/props"

export const ContentAboutWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
export const AboutContent = styled.div`
  margin: 0 auto;
  @media (min-width: ${TAB.minWidth}px) {
    width: ${TAB.contentWidth}px;
  }
  @media (min-width: ${MEDIUM.minWidth}px) {
    width: ${MEDIUM.contentWidth}px;
  }
  @media (min-width: ${LARGE.minWidth}px) {
    width: ${LARGE.contentWidth}px;
  }
`
const Section = styled.div`
  margin: 4% 0%;
  padding: 0px 10px;
`
const ContentHeading = styled.h2`
  font-size: 20px;
  font-family: ${PROP_FONT_PRIMARY};
  margin: 2% 0%;
  text-align: center;
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.aboutPage.heading}px;
  }
`
const TermHeading = styled.h4`
  font-size: 12px;
  margin: 4% 0%;
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: 16px;
  }
`

const ContentPara = styled.p`
  font-size: 12px;
  @media (min-width: ${MEDIUM.minWidth}px) {
    font-size: ${MEDIUM.aboutPage.paragraph}px;
  }
`

const ContentAbout = props => {
  return (
    <St.ContentParent>
      <St.AdWrapper>
        <St.LeftAdSection />
        <St.LeftAd2Section />
      </St.AdWrapper>

      <St.ContentWrapper>
        <St.CenterContentSection>
          <AboutContent>
            <ContentHeading>TERMS</ContentHeading>
            <ContentPara>
              This End User License Agreement and Terms of Service (this
              <b>"EULA"</b>) is a binding contract between you, an individual
              user (<b>"you"</b> or <b>"your"</b>) and Stream Africa Limited,
              Inc. (<b>"Stream-Africa"</b>, <b>"we"</b>, <b>"us"</b> or 
              <b>"our"</b>) governing your use of the software applications that
              Stream Africa makes available for download to mobile phones,
              tablets and other consumer electronic or computer devices, whether
              directly, through third parties or on a pre-installed basis
              (individually and collectively, the <b>"App"</b>), the website
              located at 
              <a href={"https://www.stream-africa.com"}>
                https://www.stream-africa.com
              </a>
               and any other online properties owned or controlled by or on
              behalf of Stream Africa (each a <b>"Website"</b> and,
              collectively, the <b>"Websites"</b>) (the App and any Websites are
              the "Service"). BY INSTALLING OR OTHERWISE ACCESSING OR USING THE
              SERVICE, YOU AGREE THAT YOU HAVE READ, UNDERSTOOD AND AGREE TO BE
              BOUND BY THIS EULA. IF YOU DO NOT AGREE TO THE TERMS OF THIS EULA,
              THEN YOU MAY NOT USE THE SERVICE. TO HAVE A COPY OF THIS EULA SENT
              TO YOU, CONTACT Stream Africa AT{" "}
              <a href="mailto:support@stream-africa.com">
                support@stream-africa.com
              </a>
               . THIS PREAMBLE IS INCORPORATED BY REFERENCE INTO THE EULA.
            </ContentPara>
            <ContentPara>
              <b>Material Terms:</b> As provided in greater detail in this EULA
              (and without limiting the express language of this EULA), you
              acknowledge the following:
            </ContentPara>
            <ContentPara>
              <ul>
                <li>
                  the Service is licensed, not sold to you, and you may use the
                  Service only as set forth in this EULA;
                </li>
                <li>
                  the use of the Service may be subject to separate third party
                  terms of service and fees, including, without limitation, your
                  mobile network operator's (the <b>"Carrier"</b>) terms of
                  service and fees, including fees charged for data usage and
                  overage, which are your sole responsibility;
                </li>
                <li>
                  for all Users (as defined below) of the Service other than
                  those located in the European Economic Area (<b>“EEA”</b>),
                  you consent to the collection, use, and disclosure of your
                  information in accordance with our policy
                </li>
                <li>
                  we provide the Service to you on an “as is” basis without
                  warranties of any kind and Stream Africa’s liability to you is
                  limited;
                </li>
                <li>
                  disputes arising between you and Stream Africa will be
                  resolved by binding arbitration. 
                  <b>
                    By accepting this EULA, as provided in greater detail in
                    Section 10 below, you and Stream Africa are each waiving the
                    right to a trial by jury or to participate in a class
                    action; and
                  </b>
                </li>
              </ul>
            </ContentPara>

            <TermHeading>1. General Terms and Conditions.</TermHeading>
            <ContentPara>
              <b>a. Changes to this EULA.</b> You understand and agree that we
              may change this EULA at any time without prior notice. We will try
              to provide you with prior notice of any material changes that may
              apply to you, including through the posting of a revised EULA that
              you may be required to accept in order to continue using the
              Service. You may read a current, effective copy of this EULA at
              any time by selecting the appropriate link on the Service. The
              revised EULA will become effective at the time of posting unless
              specified otherwise. Any use of the Service after the effective
              date will constitute your acceptance of such revised EULA. If you
              find any change to this EULA or the Service unacceptable, then
              your sole remedy is to stop accessing, browsing and otherwise
              using the Service. The terms of this EULA will govern any updates
              Stream Africa provides to you that replace and/or supplement any
              portion of the Service, unless the upgrade is accompanied by a
              separate license or revised EULA, in which case the terms of that
              license or revised EULA will govern. Notwithstanding the preceding
              sentences of this Section 1.a, no revisions to this EULA will
              apply to any dispute between you and Stream Africa that arose
              prior to the effective date of such revision.
            </ContentPara>
            <ContentPara>
              <b>
                b. Privacy Policy, Copyright Policy, Cookie Policy and
                Acceptable Use Policy.
              </b>
               Your access to and use of the Service is also subject to Stream
              Africa’s{" "}
              <u>Acceptable Use Policy, Cookie Policy, Copyright Policy</u> and{" "}
              <u>Privacy Policy</u>. For all Users of the Service other than
              those located in the EEA, the Acceptable Use Policy, Cookie
              Policy, Copyright Policy, and Privacy Policy are incorporated into
              this EULA by reference.
            </ContentPara>
            <ContentPara>
              <b>c. Jurisdictional Issues.</b> The Service is controlled and
              operated by Stream Africa from its offices in the Seychelles.
              Stream Africa makes no representation that materials on the
              Service are appropriate, lawful or available for use in any
              locations other than the Seychelles. Those who choose to access or
              use the Service from locations outside the Seychelles do so on
              their own initiative and are responsible for compliance with local
              laws, if and to the extent local laws are applicable. Access to
              the Service from jurisdictions where the contents or practices of
              the Service are illegal, unauthorized or penalized is strictly
              prohibited.
            </ContentPara>
            <ContentPara>
              <b>d. Eligibility.</b> THE SERVICE IS NOT FOR PERSONS UNDER THE
              AGE OF 13 OR FOR ANY USERS PREVIOUSLY SUSPENDED OR REMOVED FROM
              THE SERVICE BY STREAM AFRICA. IF YOU ARE UNDER 13 YEARS OF AGE,
              THEN YOU MUST NOT USE OR ACCESS THE SERVICE AT ANY TIME OR IN ANY
              MANNER. Furthermore, by accessing or using the Service, you affirm
              that either you are at least the age of majority in your
              jurisdiction or you have been authorized to use the Service by
              your parent or legal guardian who is at least the age of majority
              in your jurisdiction.
            </ContentPara>
            <ContentPara>
              <b>e. Additional Terms.</b> We also may require you to agree to
              additional terms and/or policies beyond those mentioned in Section
              1.b that we make available to you from time-to-time in connection
              with your use of the Service, including, without limitation, any
              such terms that apply to contests that we run on the Service{" "}
              <b>(“Additional Terms”)</b>. Any such Additional Terms are hereby
              incorporated into and subject to this EULA, and this EULA will
              control in the event of any conflict or inconsistency with the
              Additional Terms to the extent of the conflict or inconsistency.
            </ContentPara>

            <TermHeading>2. The Service.</TermHeading>
            <ContentPara>
              <b>a. Description.</b> Stream Africa provides an information
              location tool and audio guide service offering access to a
              selection of indexed radio stations, podcasts, artists, and other
              content, including radio stations, artists, podcasts and other
              content created by or on behalf of Stream Africa. Certain features
              of the Service are available to you free of charge (the{" "}
              <b>“Free Service”</b>). Other aspects of the service, currently
              referred to as “Stream Africa Premium,” are only available to
              paying subscribers (the <b>“Subscriptions”</b>). Any end user who
              access and uses the Free Service is a <b>“Visitor,”</b> and any
              end user who pays for a subscription is a <b>“Subscriber.”</b> The
              term <b>“Users”</b> refers to Visitors and Subscribers,
              collectively.
            </ContentPara>
            <ContentPara>
              <b>b. Consideration.</b>
            </ContentPara>
            <ContentPara>
              <b>i. Service.</b> You acknowledge and agree that this EULA is
              entered into in consideration of your use of the Service pursuant
              to this EULA and for other good and valuable consideration, the
              receipt and sufficiency of which you acknowledge.
            </ContentPara>
            <ContentPara>
              <b>ii. Subscriptions.</b> If you are a Subscriber, then you
              acknowledge and agree that the additional terms in Section 4 below
              apply to your Subscriptions, and that Stream Africa provides
              access to Subscriptions in consideration of your payment of the
              amounts described in that Section.
            </ContentPara>
            <ContentPara>
              <b>iii. No Rights to Compensation.</b> In return for enjoying the
              Free Service or Subscriptions, you further acknowledge and agree
              that Stream Africa may generate revenues (including from
              advertising and sponsorships), increase goodwill or otherwise
              increase the value of Stream Africa from your use of the Service,
              and you will have no right to share in any such revenue, goodwill
              or value whatsoever.
            </ContentPara>
            <ContentPara>
              <b>c. Changes to the Service.</b> Stream Africa may at its sole
              discretion and from time to time change, add or remove features,
              functionality and content of or available through the Service
              without any notice to you. Stream Africa may also terminate or
              discontinue support for any versions of the App, whether it was
              offered for free or for a fee. Stream Africa also reserves the
              right to discontinue some or all of the features of the Service at
              any time at its sole discretion (including the provision of
              software updates and support for different devices or platforms).
              You acknowledge and agree that Stream Africa will not be liable to
              you or to any third party for any modification, suspension or
              discontinuance of the Service, any App, or any specific
              functionality of the Service or the removal of any features,
              functionality or content. If you are dissatisfied with any changes
              to the Service, then your sole option is to discontinue or
              terminate your use of the Service as described herein.
              Notwithstanding anything in this EULA to the contrary, if you have
              signed up for a Subscription and Stream Africa cancels your
              Subscription without cause, then Stream Africa will refund to you
              the pro-rated (by month), unearned portion of any amounts that you
              have prepaid to Stream Africa for such Subscription. No refunds
              will be provided for the termination or discontinuance of support
              for any paid App.
            </ContentPara>
            <ContentPara>
              <b>d. Mobile Services.</b> The Service is currently accessible via
              computer or on a mobile phone, tablet or other connected device
              (collectively, <b>“Mobile Services”</b>). Your mobile carrier’s or
              ISPs normal messaging, data and other rates and fees will apply to
              your use of the Mobile Services. In addition, downloading,
              installing or using certain Mobile Services may be prohibited or
              restricted by your mobile carrier, and not all Mobile Services may
              work with all carriers or devices. Therefore, you are solely
              responsible for checking with your mobile carrier to determine if
              the Mobile Services are available for your mobile device(s), what
              restrictions, if any, may be applicable to your use of the Mobile
              Services, and how much they will cost you. Nevertheless, all use
              of the App and the related Mobile Services must be in accordance
              with this EULA.
            </ContentPara>
            <ContentPara>
              <b>e. Service Security.</b> You may not violate, or attempt to
              violate, the security of the Service. Any such violations may
              result in criminal and/or civil penalties against you, as well as
              the termination of your privilege to use the Service. Stream
              Africa reserves the right to investigate any alleged or suspected
              violations and, if a criminal violation is suspected, to refer
              such suspected violation to the appropriate law enforcement
              agencies and cooperate fully with such investigations. This
              cooperation may include the disclosure of all or your activities
              on or related to the Service. You agree to promptly notify Stream
              Africa if you learn or reasonably suspect that any security breach
              related to the Service has occurred.
            </ContentPara>

            <TermHeading>3. Registration.</TermHeading>
            <ContentPara>
              <b>a. Log-In Credentials.</b> While you may access the Free
              Service and browse the public-facing portions of the Service
              without registering with us, in order to access Subscriptions and
              enjoy the full functionality and benefits of the Service you must
              register an account with us (an <b>“Account”</b>).
            </ContentPara>
            <ContentPara>
              <b>b. Account Security.</b> You are responsible for the security
              of your Account, and are fully responsible for all activities that
              occur through the use of your Account credentials. You agree to
              notify Stream Africa immediately at{" "}
              <a href="mailto:support@stream-africa.com">
                support@stream-africa.com
              </a>{" "}
              if you suspect or know of any unauthorized use of your Account
              credentials or any other breach of security with respect to your
              Account. Stream Africa will not be liable for any loss or damage
              arising from unauthorized use of your Account credentials prior to
              you notifying Stream Africa of such unauthorized use or loss of
              your Account credentials.
            </ContentPara>
            <ContentPara>
              <b>c. Accuracy of Information.</b> When creating an Account, you
              will provide true, accurate, current and complete information as
              Stream Africa requests. You will update the information about
              yourself promptly, and as necessary, to keep it current and
              accurate. We reserve the right to disallow, cancel, remove or
              reassign certain usernames and permalinks in appropriate
              circumstances, as determined by us in our sole discretion, and
              may, with or without prior notice, suspend or terminate your
              Account if activities occur on your Account which, in our sole
              discretion, would or might constitute a violation of this EULA,
              cause damage to or impair the Service, infringe or violate any
              third party rights, damage or bring into disrepute the reputation
              of Stream Africa, or violate any applicable laws or regulations.
              If messages sent to the e-mail address you provide are returned as
              undeliverable, then Stream Africa may terminate your Account
              immediately without notice to you and without any liability to you
              or any third party.
            </ContentPara>

            <TermHeading>4. Additional Terms for Subscription.</TermHeading>
            <ContentPara>
              <b>a. Subscription Process.</b> You may purchase a Subscription
              through the Website, through our App, or through such other
              channels as Stream Africa may make available from time-to-time in
              its sole discretion. Where you purchase a Subscription through the
              Website or another channel controlled by Stream Africa, your
              payments are processed by our third party payment processors.
              Where you purchase a Subscription through our iOS or Android Apps
              or through a third party’s consumer electronic devices, your
              payments are processed by Apple or Google, or the manufacturer of
              the consumer electronic device (or any of their third party
              payment processors), as applicable. We accept various forms of
              payment, as specified during sign-up. We do not accept cash or
              checks.
            </ContentPara>
            <ContentPara>
              <b>b. Trials.</b> From time to time, Stream Africa or others on
              our behalf may offer trials of Subscriptions for a specified
              period free-of-charge or at a reduced rate (<b>“Trial”</b>). The
              length of any Trial may vary. Trials are for new and certain
              former Subscribers only. Stream Africa reserves the right, at our
              discretion, to determine your eligibility for a Trial, and,
              subject to applicable laws, to withdraw or to modify a Trial at
              any time without prior notice and with no liability, to the
              greatest extent permitted under the law. We will require you to
              provide your payment details at the start of the Trial. The first
              day following the end of your Trial or, if you are not eligible
              for a Trial, then the day you purchase your Subscription, is the
              first day of your billing cycle (<b>“Billing Date”</b>). YOU will
              BE automatically charged for the applicable Subscription on the
              first day following the end of the Trial, on a recurring monthly
              or annual basis, unless you cancel the applicable Subscription
              before the end of the Trial.
            </ContentPara>
            <ContentPara>
              <b>c. Billing Authorization.</b> BY PROVIDING YOUR PAYMENT METHOD
              AND OBTAINING A SUBSCRIPTION TO THE SERVICE, YOU AUTHORIZE STREAM
              AFRICA, APPLE, OR GOOGLE (OR ANY OF THEIR SERVICE PROVIDERS), AS
              APPLICABLE, TO CHARGE TO YOUR DESIGNATED PAYMENT METHOD THE
              APPLICABLE MONTHLY OR ANNUALLY RECURRING SUBSCRIPTION FEES (
              <b>“SUBSCRIPTION FEE”</b>), AND ANY APPLICABLE SALES,
              TELECOMMUNICATION, EXCISE, VALUED-ADDED OR SIMILAR TAXES (
              <b>“TAXES”</b>). THE SUBSCRIPTION FEE IS EXCLUSIVE OF ANY SUCH
              TAXES. THE SUBSCRIPTION FEE IS PAYABLE IN THE CURRENCY SPECIFIED
              DURING SIGN-UP.
            </ContentPara>
            <ContentPara>
              <b>d. Subscription Fee Changes.</b> TO THE FULLEST EXTENT
              PERMITTED UNDER APPLICABLE LAW, WE RESERVE THE RIGHT TO CHANGE THE
              SUBSCRIPTION FEE AT ANY TIME IN OUR SOLE DISCRETION. IF WE CHANGE
              THE SUBSCRIPTION FEE, THEN WE WILL PROVIDE YOU NOTICE OF THE
              PROPOSED CHANGE AT LEAST 30 DAYS BEFORE THE CHANGE IS TO TAKE
              EFFECT. SUBSCRIPTION FEE CHANGES WILL TAKE EFFECT AT THE START OF
              THE NEXT RENEWAL OF YOUR SUBSCRIPTION FOLLOWING THE DATE OF THE
              PRICE CHANGE. YOU ACCEPT THE NEW PRICE BY CONTINUING TO USE THE
              SERVICE AFTER THE PRICE CHANGE TAKES EFFECT. IF YOU DO NOT ACCEPT
              THE PRICE CHANGE, THEN YOU MAY UNSUBSCRIBE FROM THE SERVICE PRIOR
              TO THE PRICE CHANGE GOING INTO EFFECT.
            </ContentPara>
            <ContentPara>
              <b>e. Automatic Renewal.</b> TO THE FULLEST EXTENT PERMITTED UNDER
              APPLICABLE LAW, BY PURCHASING A SUBSCRIPTION TO THE SERVICE, YOU
              AGREE THAT YOUR SUBSCRIPTION WILL AUTOMATICALLY RENEW AT THE END
              OF EACH SUBSCRIPTION TERM AT THE THEN-CURRENT SUBSCRIPTION FEE AND
              FOR THE SAME TERM AS THE IMMEDIATELY PRECEDING SUBSCRIPTION,
              UNLESS AND UNTIL YOU CANCEL YOUR SUBSCRIPTION.
            </ContentPara>
            <ContentPara>
              <b>f. Recurring Charges.</b> TO THE FULLEST EXTENT PERMITTED UNDER
              APPLICABLE LAW, UPON EXPIRATION OF YOUR SUBSCRIPTION, YOU WILL
              AUTOMATICALLY BE BILLED FOR THE SAME SUBSCRIPTION PLAN (OR THE
              MOST SIMILAR SUBSCRIPTION PLAN, IF YOUR PRIOR PLAN IS NO LONGER
              AVAILABLE) AT THE THEN-CURRENT SUBSCRIPTION FEE PLUS ANY TAXES.
              YOUR PAYMENT METHOD WILL BE CHARGED THE SUBSCRIPTION FEE FOR THE
              RENEWAL TERM OF YOUR SUBSCRIPTION ON YOUR BILLING DATE, BASED ON
              THE TYPE OF SUBSCRIPTION (E.G., ON A PARTICULAR DATE EACH MONTH
              FOR A MONTHLY SUBSCRIPTION). IF, HOWEVER, THE BILLING DATE OF YOUR
              SUBSCRIPTION IS ON THE 29TH, 30TH OR 31ST DAY OF A MONTH, AND YOUR
              BILLING DATE DOES NOT EXIST IN A PARTICULAR MONTH BECAUSE THERE
              ARE FEWER THAN THIRTY-ONE (31) DAYS IN THE MONTH PAYMENT IS DUE,
              THEN YOUR BILLING DATE FOR THAT PARTICULAR RENEWAL WILL BE ON THE
              LAST DAY OF THE MONTH IN WHICH YOUR BILLING DATE WOULD OTHERWISE
              BE.
            </ContentPara>
            <ContentPara>
              <b>g. Cancellation and Refunds.</b> YOU MAY CANCEL YOUR
              SUBSCRIPTION TO THE SERVICE AT ANY TIME BY FOLLOWING THE PROCESS
              DESCRIBED HERE. IF YOU CANCEL YOUR SUBSCRIPTION, THEN YOU WILL
              HAVE ACCESS TO THE SUBSCRIPTION UNTIL THE DATE THAT WOULD HAVE
              BEEN YOUR NEXT BILLING DATE, AFTER WHICH YOU WILL BE DOWNGRADED TO
              THE FREE SERVICE. IF YOU SUBSCRIBED THROUGH YOUR IOS OR ANDROID
              DEVICE AND WANT TO OBTAIN A REFUND IN CONNECTION WITH YOUR
              SUBSCRIPTION, THEN YOU MUST CONTACT APPLE OR GOOGLE, AS
              APPLICABLE. IF YOU SUBSCRIBED THROUGH THE WEBSITE, THEN SUBJECT TO
              SECTION 4.h BELOW, WE WILL NOT REFUND ANY FEES PAID FOR YOUR
              CURRENT SUBSCRIPTION PERIOD.
            </ContentPara>
            <ContentPara>
              <b>h. Cooling-off Period.</b> If you reside in the European Union
              (or any other jurisdiction that requires a cooling-off period
              following the purchase of a Subscription to the Service) and
              register for a Subscription, then you may cancel your Subscription
              for any reason or no reason and receive a full refund of your
              Subscription Fee within 14 days of your payment method being
              charged (“Cooling-off Period”) unless you have previously accessed
              your Subscription (i.e., you receive a transmission through the
              Service). If you have accessed the Service following the date of
              charge of your payment method pursuant to your Subscription at any
              time during the Cooling-off Period, however, then we will not
              refund any Subscription Fees already paid to us.
            </ContentPara>
            <ContentPara>
              <b>i. Failure to Pay.</b> If you fail to pay the applicable
              Subscription Fee when due, then you will be downgraded to the Free
              Service. If you are downgraded, then Stream Africa may, but is not
              obligated to, maintain your Account and/or related content and
              information, in order to allow you to pay the past-due charges and
              restore your Subscription.
            </ContentPara>

            <TermHeading>5. Intellectual Property Rights.</TermHeading>
            <ContentPara>
              <b>a. Service.</b> Subject to your complete and ongoing compliance
              with this EULA, Stream Africa hereby grants you a revocable,
              non-exclusive, non-transferable, non-sublicensable, royalty-free
              and worldwide right and license to access and use the Service
              solely for your personal, non-commercial entertainment use as
              intended through the provided functionality of the Service and
              solely in strict compliance with the provisions of this EULA. You
              may not alter, modify, creative derivative works of, sell, license
              or in any way exploit any part of the Service except as expressly
              set forth in the preceding sentence.
            </ContentPara>
            <ContentPara>
              <b>b. Stream Africa Content.</b> Except for User Content (defined
              below) and third party content owned or controlled by third party
              transmitting entities over whom Stream Africa does not exercise a
              right and ability to control and where such content is made
              available through the Service when Stream Africa is providing an
              information location tool, index or directory service (
              <b>“Third Party Content”</b>), the content that Stream Africa
              itself provides to Users on or through the Service, including
              without limitation, any radio programs, podcasts or other audio
              content, text, graphics, photos, software and interactive
              features, may be protected by copyright or other intellectual
              property rights and is owned by Stream Africa or its third party
              licensors (collectively, the <b>“Stream Africa Content”</b>). You
              may not reproduce, modify, distribute, display, publish, transmit,
              communicate to the public, make available, broadcast, create
              derivative works from, publicly perform (including on a
              through-to-the audience basis) and otherwise use and exploit
              (collectively, <b>“Use”</b>) any Stream Africa Content without
              Stream Africa’s prior authorization. You may not Use any User
              Content or Third Party Content without the prior authorization of
              the owners of such content, notwithstanding any functionalities of
              the Service. Stream Africa does not guarantee access to any
              particular User Content, Stream Africa Content, or Third Party
              Content. Stream Africa or its licensors, and the owners of User
              Content and Third Party Content, may change, delete, or prohibit
              access to Stream Africa Content, User Content or Third Party
              Content, as the case may be, or schedules of any of the foregoing,
              at any time with or without notice. You understand that by using
              the Service, you may encounter User Content, Third Party Content,
              and Stream Africa Content that you may find offensive or
              objectionable and that might constitute Objectionable Content as
              defined in our <u>Acceptable Use Policy</u>. Stream Africa is not
              the publisher of, nor responsible for, any Objectionable Content
              made available in User Content or Third Party Content, and to the
              fullest extent permitted by law, Stream Africa will have no
              liability to you with respect to Objectionable Content you access
              via the Service. User Content, Third Party Content, and Stream
              Africa Content may be transmitted with a variety of copy
              protection mechanisms. These copy protection mechanisms may be
              designed to protect the copyright interests of Stream Africa or
              its licensors or third party transmitting entities, as applicable.
              These copy protection mechanisms may limit or prevent the ability
              of the Service to play Stream Africa Content, User Content or
              Third Party Content. You agree that Stream Africa will have no
              liability to you or any third party with regard to your ability to
              access, listen to, or otherwise use, any Stream Africa Content,
              User Content or Third Party Content due to a copy protection
              mechanism. You may not remove or attempt to circumvent any
              copyright protection mechanisms in any Stream Africa Content, User
              Content or Third Party Content without express authorization of
              the owner of such content or their authorized agent, or as
              otherwise permitted by law, and any attempts to do so may subject
              you to liability.
            </ContentPara>
            <ContentPara>
              <b>c. Marks.</b> Stream Africa trademarks, service marks and logos
              (the <b>“Stream Africa Trademarks”</b>) used and displayed on the
              Service are Stream Africa’s registered and unregistered trademarks
              or service marks. Other product and service names located on the
              Service may be trademarks or service marks owned by third parties
              (the <b>“Third-Party Trademarks,”</b> and, collectively with
              Stream Africa Trademarks, the <b>“Trademarks”</b>). Except as
              otherwise permitted by law, you may not use the Trademarks to
              disparage Stream Africa or the applicable third-party, Stream
              Africa’s or a third-party’s products or services, or in any manner
              (using commercially reasonable judgment) that may damage any
              goodwill in the Trademarks. You may not use any Trademarks as part
              of a link to or from any Service without Stream Africa’s prior
              express written consent. All goodwill generated from the use of
              any Stream Africa Trademark will inure solely to Stream Africa’s
              benefit.
            </ContentPara>
            <ContentPara>
              <b>d. Restrictions.</b> The Service, Stream Africa Content, and
              Trademarks, including all intellectual property rights therein,
              are the sole and exclusive property of Stream Africa and its
              licensors. Stream Africa hereby reserves all rights not expressly
              granted to you in this Section 5. Accordingly, nothing in this
              EULA or on the Service will be construed as granting to you, by
              implication, estoppel or otherwise, any additional license rights
              in and to the Service or any Stream Africa Content or Trademarks
              located or displayed therein. Except for the right to access Third
              Party Content through the Service, no rights are hereby granted to
              you with respect to such Third Party Content.
            </ContentPara>

            <TermHeading>6. User Content and Other Content.</TermHeading>
            <ContentPara>
              <b>a. Definition.</b> This Section governs any material that you
              post, send or transmit (collectively, <b>“Post”</b>) through the
              Service, including, by way of example and not limitation,
              photographs, graphics, images and text (collectively,{" "}
              <b>“User Content”</b>) and other activities that Stream Africa may
              take with respect to materials accessible through the Service.
            </ContentPara>
            <ContentPara>
              <b>b. Licenses to User Content.</b> By Posting User Content to or
              through the Service, you hereby grant to Stream Africa (and Stream
              Africa’s assignees, designees, successors, licensees, and
              sub-licensees) an unrestricted, perpetual, assignable,
              sublicensable, revocable, royalty-free, fully paid-up license
              throughout the world to Use all User Content you Post to or
              through the Service, through any media and formats now known or
              hereafter developed, in connection with the Service or our
              business.
            </ContentPara>
            <ContentPara>
              <b>c. You Must Have Rights to the Content You Post.</b> You must
              not Post any User Content to the Service if you are not the owner
              of or are not fully authorized to grant rights throughout the
              universe in all elements of the User Content you intend to Post to
              the Service . You represent and warrant that: (i) you own the User
              Content Posted by you on or through the Service or otherwise have
              the right to grant the license set forth in this EULA; (ii) the
              Posting and Use of your User Content on or through the Service
              does not violate the privacy rights, publicity rights, copyrights,
              contract rights, intellectual property rights or any other rights
              of any party in any territory throughout the universe; (iii) the
              Posting of your User Content on the Service will not require us to
              obtain any further licenses from or pay any royalties, fees,
              compensation or other amounts or provide any attribution to any
              third parties; and (iv) the Posting of your User Content on the
              Service does not result in a breach of contract between you and a
              third party. You agree to pay all monies owing to any person as a
              result of Posting your User Content on the Service.
            </ContentPara>
            <ContentPara>
              <b>d. Acceptable Uses and Objectionable Content.</b> We want Users
              to have an enjoyable experience when they use the Service. To
              facilitate that, we have adopted an Acceptable Use Policy, which
              is hereby incorporated by reference into this EULA. You are
              required to comply with the Acceptable Use Policy when Posting any
              User Content to the Service. The Posting of any Objectionable
              Content, as defined in the Acceptable Use Policy, may subject you
              to third party claims and none of the rights granted to you in
              this EULA may be raised as a defense against any third party
              claims arising from your Posting of Objectionable Content. If you
              encounter any Objectionable Content on the Service, then please
              immediately email Stream Africa at{" "}
              <a href="mailto:support@stream-africa.com">
                support@stream-africa.com
              </a>
              . You acknowledge and agree that Stream Africa provides you with
              the ability to report Objectionable Content as a courtesy, and
              Stream Africa has no obligation to remove or take any other action
              with respect to any Objectionable Content on the Service that you
              report to us. However, Stream Africa in its sole discretion may
              take any actions it deems necessary and/or appropriate against any
              User who Posts Objectionable Content on the Service, including,
              but not limited to, warning the User, suspending or terminating
              the User’s Account, removing all of the User’s User Content Posted
              on the Service, and/or reporting the User to law enforcement or
              other governmental authorities, either directly or indirectly.
            </ContentPara>
            <ContentPara>
              <b>e. Monitoring.</b> Stream Africa has the right, but not the
              obligation, to monitor the Service, and may remove or restrict
              access to any User Content, Third Party Content or Stream Africa
              Content from the Service for any reason in its sole discretion at
              any time, without notice and without any liability to you. You
              acknowledge that Stream Africa may establish general practices and
              limits concerning use of the Service without notice to you,
              including, without limitation, the maximum period of time that
              User Content or Stream Africa Content will be retained by the
              Service and the maximum storage space that will be allotted on
              Stream Africa’s servers on your behalf. You acknowledge and agree
              that Stream Africa may preserve any content and may also disclose
              any content if required to do so by law or in the good faith
              belief that such preservation or disclosure is reasonably
              necessary to: (i) comply with legal process, applicable laws or
              government requests; (ii) enforce this EULA; (iii) respond to
              claims that any content violates the rights of third parties; or
              (iv) protect the rights, property, or personal safety of Stream
              Africa, its Users and the public. You understand that Stream
              Africa does not guarantee any confidentiality with respect to User
              Content and will have no responsibility or liability for User
              Content that is deleted from the Service.
            </ContentPara>

            <TermHeading>
              7. Special Notice for International Use; Export Controls.
            </TermHeading>
            <ContentPara>
              Software available in connection with the Service and the
              transmission of applicable data, if any, is subject to Seychelles
              export controls. No software may be downloaded from the Service or
              otherwise exported or re-exported in violation of export laws. You
              download and use the software at your sole risk. Recognizing the
              global nature of the Internet, you agree to comply with all local
              rules and laws regarding your use of the Service, including as it
              concerns online conduct and acceptable content. Stream Africa
              makes no representation that the Service is appropriate or
              available for use in any particular location.
            </ContentPara>

            <TermHeading>8. Advertisements and External Sites.</TermHeading>
            <ContentPara>
              <b>a. Advertisements.</b> Stream Africa may display advertisements
              for the goods and services of third parties or other promotional
              materials on the Service. Your dealings with, or participation in,
              promotions of any third party advertisers on the Service, are
              solely between you and such third party, and your participation is
              subject to any terms and conditions associated with that
              advertisement or promotion. Stream Africa does not endorse or
              represent such third parties, and by using the Service, you agree
              that Stream Africa is not responsible or liable for any loss or
              damage of any sort incurred as a result of any such dealings or
              the presence of such third parties on the Service.
            </ContentPara>
            <ContentPara>
              <b>b. External Sites.</b> The Service may contain links to or the
              ability to share information with third party websites (
              <b>“External Sites”</b>). Stream Africa does not endorse any
              External Sites or the content made available on such External
              Sites. All External Sites and any content thereon is developed and
              provided by others. You should contact the site administrator or
              Webmaster for those External Sites if you have any concerns
              regarding such content located on such External Sites. Stream
              Africa is not responsible for the content of any External Sites
              and does not make any representations regarding the content or
              accuracy of any materials on such External Sites. You should take
              precautions when downloading files from all websites to protect
              your computer and connected devices from viruses and other
              destructive programs. If you decide to access any External Sites,
              purchase any content from External Sites or subscribe to services
              offered by such External Site, then you do so at your own risk.
              You agree that Stream Africa will have no liability to you arising
              from your use, engagement, exposure to or interaction with any
              External Sites.
            </ContentPara>

            <TermHeading>9. Feedback.</TermHeading>
            <ContentPara>
              While we are continually working to develop and evaluate our own
              product ideas and features, we pride ourselves on paying close
              attention to your interests, feedback, comments and suggestions.
              If you choose to contribute by sending Stream Africa or our
              employees any ideas for products, services, features,
              modifications, enhancements, content, refinements, technologies,
              content offerings (such as audio, visual, audiovisual, games or
              other types of content), promotions, strategies or product/feature
              names or any related documentation, artwork, computer code,
              diagrams or other materials (collectively <b>“Feedback”</b>), then
              regardless of what your accompanying communication may say, the
              following terms will apply, so that future misunderstandings can
              be avoided. Accordingly, by sending Feedback to Stream Africa, you
              agree that:
            </ContentPara>
            <ContentPara>
              a. Stream Africa has no obligation to review, consider or
              implement your Feedback, or to return to you all or part of any
              Feedback for any reason;
            </ContentPara>
            <ContentPara>
              b. Feedback is provided on a non-confidential basis, and Stream
              Africa is not under any obligation to keep any Feedback you send
              confidential or to refrain from using or disclosing it in any way;
              and
            </ContentPara>
            <ContentPara>
              c. You irrevocably grant Stream Africa and its successors and
              assigns perpetual and unlimited permission to Use the Feedback and
              derivatives thereof for any purpose and without restriction, free
              of charge and without attribution of any kind, including by
              making, using, selling, offering for sale, importing and promoting
              commercial products and services that incorporate or embody
              Feedback, whether in whole or in part, and whether as provided or
              as modified.
            </ContentPara>

            <TermHeading>10. Dispute Resolution.</TermHeading>
            <ContentPara>
              <b>a. General.</b> In the interest of resolving disputes between
              you and Stream Africa in the most expedient and cost effective
              manner, you and Stream Africa agree that any dispute arising out
              of or in any way related to this EULA or your use of the Service
              will be resolved by binding arbitration. Arbitration is less
              formal than a lawsuit in court. Arbitration uses a neutral
              arbitrator instead of a judge or jury, may allow for more limited
              discovery than in court, and can be subject to very limited review
              by courts. Arbitrators can award the same damages and relief that
              a court can award. This agreement to arbitrate disputes includes
              all claims arising out of or in any way related to this EULA or
              your use of the Service, whether based in contract, tort, statute,
              fraud, misrepresentation or any other legal theory, and regardless
              of whether a claim arises during or after the termination of this
              EULA. YOU UNDERSTAND AND AGREE THAT, BY ENTERING INTO THIS EULA,
              YOU AND STREAM AFRICA ARE EACH WAIVING THE RIGHT TO A TRIAL BY
              JURY OR TO PARTICIPATE IN A CLASS ACTION AND THAT THIS AGREEMENT
              SHALL BE SUBJECT TO AND GOVERNED BY THE FEDERAL ARBITRATION ACT.
            </ContentPara>
            <ContentPara>
              <b>b. Exceptions.</b> Notwithstanding Section 10.a above, nothing
              in this EULA will be deemed to waive, preclude or otherwise limit
              the right of either party to: (i) bring an individual action in
              small claims court; (ii) pursue an enforcement action through the
              applicable federal, state or local agency if that action is
              available; (iii) seek injunctive relief in aid of arbitration from
              a court of competent jurisdiction; or (iv) to file suit in a court
              of law to address an intellectual property infringement claim.
            </ContentPara>
            <ContentPara>
              <b>c. Arbitrator.</b> Any arbitration between you and Stream
              Africa will be governed by the Arbitration Act and the Commercial
              Dispute Resolution Procedures and Supplementary Procedures for
              Consumer Related Disputes (collectively, <b>“AA Rules”</b>) of the
              Arbitration Association (<b>“AA”</b>), as modified by this EULA,
              and will be administered by the AA. The arbitrator has exclusive
              authority to resolve any dispute relating to the interpretation,
              applicability or enforceability of this binding arbitration
              agreement.
            </ContentPara>
            <ContentPara>
              <b>d. Notice; Process.</b> A party who intends to seek arbitration
              must first send a written notice of the dispute to the other party
              by Mail (<b>“Notice”</b>) or, if a postal address is not
              available, to the last known email address for such party. Stream
              Africa’s email address for Notice is:{" "}
              <a href="mailto:support@stream-africa.com">
                support@stream-africa.com
              </a>
              . The Notice must: (i) describe the nature and basis of the claim
              or dispute; and (ii) set forth the specific relief sought (
              <b>“Demand”</b>). The parties will make good faith efforts to
              resolve the claim directly, but if the parties do not reach an
              agreement to do so within 30 days after the Notice is received,
              you or Stream Africa may commence an arbitration proceeding.
              During the arbitration, the amount of any settlement offer made by
              you or Stream Africa must not be disclosed to the arbitrator until
              after the arbitrator makes a final decision and award, if any.
            </ContentPara>
            <ContentPara>
              <b>e. Fees.</b> If you commence arbitration in accordance with
              this EULA, Stream Africa will not be liable for any fees incurred.
            </ContentPara>
            <ContentPara>
              <b>f. No Class Actions.</b> YOU AND STREAM AFRICA AGREE THAT EACH
              MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL
              CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
              CLASS OR REPRESENTATIVE PROCEEDING. Further, unless both you and
              Stream Africa agree otherwise, the arbitrator may not consolidate
              more than one person’s claims, and may not otherwise preside over
              any form of a representative or class proceeding.
            </ContentPara>
            <ContentPara>
              <b>g. Modifications to this Arbitration Provision.</b> Except as
              otherwise provided in this EULA, if Stream Africa makes any future
              change to this arbitration provision, other than a change to
              Stream Africa’s address for Notice, then you may reject the change
              by sending us written notice within 30 days of the change to
              Stream Africa’s address for Notice, in which case this arbitration
              provision, as in effect immediately prior to the changes you
              rejected, will continue to govern any disputes between you and
              Stream Africa.
            </ContentPara>
            <ContentPara>
              <b>h. Enforceability.</b> If Section 10.f above is found to be
              unenforceable or if the entirety of this Section 10 is found to be
              unenforceable, then the entirety of this Section 10 will be null
              and void.
            </ContentPara>

            <TermHeading>
              11. Limitation of Liability and Disclaimer of Warranties.
            </TermHeading>
            <ContentPara>
              THE FOLLOWING TERMS IN THIS SECTION 11 APPLY TO THE FULLEST EXTENT
              PERMITTED BY LAW:
            </ContentPara>
            <ContentPara>
              a. STREAM AFRICA, ITS AFFILIATES AND THEIR RESPECTIVE OFFICERS,
              DIRECTORS, EMPLOYEES, AGENTS, SUPPLIERS AND LICENSORS
              (COLLECTIVELY, THE <b>“STREAM AFRICA PARTIES”</b>) MAKE NO
              WARRANTIES OR REPRESENTATIONS ABOUT THE SERVICE AND ANY CONTENT
              AVAILABLE ON THE SERVICE, INCLUDING, BUT NOT LIMITED TO, THE
              ACCURACY, RELIABILITY, COMPLETENESS APPROPRIATENESS, TIMELINESS OR
              RELIABILITY THEREOF. THE STREAM AFRICA PARTIES WILL NOT BE SUBJECT
              TO LIABILITY FOR THE TRUTH, ACCURACY OR COMPLETENESS OF ANY
              CONTENT ON THE SERVICE OR ANY OTHER INFORMATION CONVEYED TO ANY
              USER, OR FOR ERRORS, MISTAKES OR OMISSIONS THEREIN, OR FOR ANY
              DELAYS OR INTERRUPTIONS OF THE DATA FROM WHATEVER CAUSE. AS A
              USER, YOU AGREE THAT YOU USE THE SERVICE AND ANY CONTENT THEREON
              AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR ALL CONTENT YOU
              UPLOAD TO THE SERVICE.
            </ContentPara>
            <ContentPara>
              b. THE STREAM AFRICA PARTIES DO NOT WARRANT THAT THE SERVICE WILL
              OPERATE ERROR FREE, OR THAT THE SERVICE AND ANY CONTENT THEREON
              ARE FREE OF COMPUTER VIRUSES OR SIMILAR CONTAMINATION OR
              DESTRUCTIVE FEATURES. IF YOUR USE OF THE SERVICE OR ANY CONTENT
              THEREON RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT
              OR DATA, THEN NO STREAM AFRICA PARTY WILL BE RESPONSIBLE FOR THOSE
              COSTS.
            </ContentPara>
            <ContentPara>
              c. THE SERVICE AND ALL CONTENT THEREON ARE PROVIDED ON AN “AS IS”
              AND “AS AVAILABLE” BASIS WITHOUT ANY WARRANTIES OF ANY KIND.
              ACCORDINGLY, THE STREAM AFRICA PARTIES DISCLAIM ALL WARRANTIES,
              INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF TITLE,
              MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS AND
              FITNESS FOR PARTICULAR PURPOSE.
            </ContentPara>
            <ContentPara>
              d. IN NO EVENT WILL ANY STREAM AFRICA PARTY BE LIABLE FOR ANY
              SPECIAL, INDIRECT, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES,
              LOST PROFITS OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS
              INTERRUPTION RESULTING FROM, OR IN CONNECTION WITH, THE USE OR
              INABILITY TO USE THE SERVICE AND ANY CONTENT THEREON, WHETHER
              BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY
              OTHER LEGAL THEORY, EVEN IF SUCH STREAM AFRICA PARTY HAS BEEN
              ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </ContentPara>

            <TermHeading>12. Third Party Disputes.</TermHeading>
            <ContentPara>
              TO THE FULLEST EXTENT PERMITTED BY LAW, ANY DISPUTE YOU HAVE WITH
              ANY THIRD PARTY ARISING OUT OF YOUR USE OF THE SERVICE, INCLUDING,
              BY WAY OF EXAMPLE AND NOT LIMITATION, ANY CARRIER OR OTHER USER,
              IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY
              RELEASE THE STREAM AFRICA PARTIES FROM ANY AND ALL CLAIMS, DEMANDS
              AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE,
              KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH
              SUCH DISPUTES.
            </ContentPara>

            <TermHeading>13. Indemnification.</TermHeading>
            <ContentPara>
              To the fullest extent permitted by law, you agree to indemnify and
              hold Stream Africa, and its officers, directors, employees,
              agents, successors, and assigns harmless from and against any
              claims, liabilities, damages, losses, and expenses, including,
              without limitation, reasonable legal and accounting fees, arising
              out of or in any way connected to (a) your access, use, or misuse
              of the Service or Stream Africa Content; (b) your User Content; or
              (c) your violation of this EULA. Stream Africa will use reasonable
              efforts to notify you of any such claim, action or proceeding for
              which it seeks an indemnification from you upon becoming aware of
              it, but if Stream Africa is unable to communicate with you in a
              timely manner because of an inactive e-mail address for you, then
              your indemnification obligation will continue notwithstanding
              Stream Africa’s inability to contact you in a timely manner.
              Stream Africa reserves the right, in its sole discretion, to
              assume the exclusive defense and control of any matter that is
              subject to indemnification under this Section at your sole
              expense. In such case, you agree to cooperate with any reasonable
              requests to assist Stream Africa’s defense of such matter.
            </ContentPara>

            <TermHeading>14. Term and Termination of the EULA.</TermHeading>
            <ContentPara>
              <b>a. Term.</b> As between you and Stream Africa, the Term of this
              EULA commences as of your first use of the Service and continues
              until the termination of this EULA by either you or Stream Africa.
            </ContentPara>
            <ContentPara>
              <b>b. Termination.</b> You may terminate this EULA by sending
              written notification to Stream Africa at{" "}
              <a href="mailto:support@stream-africa.com">
                support@stream-africa.com
              </a>
              , deleting the App from your connected device and terminating all
              other uses of the Service. Stream Africa reserves the right, in
              its sole discretion, to restrict, suspend or terminate this EULA
              and your access to all or any part of the Service at any time
              without prior notice or liability if you breach any provision of
              this EULA or violate the rights of any third party. Stream Africa
              may further terminate this EULA immediately for any other reason
              with or without notice to you, using the email address associated
              with your Account credentials. Stream Africa reserves the right to
              change, suspend or discontinue all or any part of the Service at
              any time without prior notice or liability.
            </ContentPara>
            <ContentPara>
              c. After any termination of this EULA, you understand and
              acknowledge that (i) we will have no further obligation to provide
              the Service to you; and (ii) all licenses and other rights granted
              to you in this EULA will immediately cease. Stream Africa is not
              liable to you or any third party for termination of the Service or
              termination of your use of the Service. Upon any termination or
              suspension, you may no longer access any information (including
              your User Content) that you have Posted to the Service or that is
              related to your Account. Furthermore, Stream Africa will have no
              obligation to maintain any information stored in our database
              related to your Account or to forward any information to you or
              any third party. You acknowledge and agree that Stream Africa will
              not be required to delete any of your User Content that may be
              stored on any servers or other back-up media owned or controlled
              by or on behalf of Stream Africa following the termination of your
              Account. Sections 1.a, 1.c, 1.d, 1.e, 2.b, 2.c, 2.d, 2.e, 3.b,
              5.b, 5.c, 5.d, 6, 7, 9, 10, 11, 12, 13, 14.b, 14.c, 15, 16, 18 and
              all defined terms used therein will survive the termination of
              this EULA indefinitely.
            </ContentPara>

            <TermHeading>15. Consent to Electronic Communications.</TermHeading>
            <ContentPara>
              By using the Service, you consent to receiving certain electronic
              communications from us as further described in the{" "}
              <u>Privacy Policy</u>. Please read the <u>Privacy Policy</u> to
              learn more about your choices regarding our electronic
              communications practices. You agree that any notices, agreements,
              disclosures or other communications that we send to you
              electronically will satisfy any legal communication requirements,
              including that such communications be in writing.
            </ContentPara>

            <TermHeading>16. Miscellaneous.</TermHeading>
            <ContentPara>
              The United Nations Convention on Contracts for the International
              Sale of Goods does not apply to this EULA. This EULA, the{" "}
              <u>Copyright Policy</u> and any other agreements expressly
              incorporated by reference herein, constitute the entire and
              exclusive understanding and agreement between you and Stream
              Africa regarding your use of and access to the Service, and,
              except as expressly permitted in Section 1.a above, may be amended
              only by a written agreement signed by you and an authorized
              representative of Stream Africa. In addition to the foregoing, for
              Users located in the EEA, you acknowledge that certain aspects of
              your use of the Service may be governed by additional policies,
              including Stream Africa’s <u>Cookie Policy</u> and{" "}
              <u>Privacy Policy</u>. The failure to require performance of any
              provision of this EULA will not affect our right to require
              performance at any time thereafter, nor will a waiver of any
              breach or default of this EULA or any provision of this EULA
              constitute a waiver of any subsequent breach or default or a
              waiver of the provision itself. Use of section headers in this
              EULA is for convenience only and will not have any impact on the
              interpretation of particular provisions. In the event that any
              part of this EULA is held to be invalid or unenforceable, the
              unenforceable part will be given effect to the greatest extent
              possible and the remaining parts will remain in full force and
              effect. You agree that no joint venture, partnership, employment,
              or agency relationship exists between you and Stream Africa as a
              result of this EULA or use of the Service. You acknowledge and
              agree that certain other third parties with whom Stream Africa
              does business, are third party beneficiaries of this EULA, and
              that such third parties will have the right to enforce this EULA
              against you as a third party beneficiary thereof. The English
              language version of this EULA is the version that governs your use
              of the Service and in the event of any conflict between the
              English language version and a translated version, the English
              language version will control. You expressly agree that this EULA
              and all ancillary documents be drafted solely in English.
            </ContentPara>

            <TermHeading>17. Contact Us.</TermHeading>
            <ContentPara>
              If you have any comments, complaints, suggestions about this EULA
              or the Service or otherwise would like to contact us, then you can
              contact Stream Africa by email at{" "}
              <a href="mailto:support@stream-africa.com">
                support@stream-africa.com
              </a>
              ,
            </ContentPara>

            <TermHeading>18. Open Source Software.</TermHeading>
            <ContentPara>
              The App contains certain open source software. Each item of open
              source software is subject to its own applicable license terms.
            </ContentPara>
          </AboutContent>
        </St.CenterContentSection>
        <St.BottomAdSection />
      </St.ContentWrapper>
      <St.AdWrapper>
        <St.RightAdSection />
        <St.RightAd2Section />
      </St.AdWrapper>
    </St.ContentParent>
  )
}

export default ContentAbout
